<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="名称">
              <a-input v-model="queryParam.name" placeholder="名称"/>
            </a-form-item>
          </a-col>
          <a-col :md="3" :sm="24">
            <a-form-item label="非零">
              <el-switch
                v-model="queryParam.no_zero"
                active-color="#1890ff"
                inactive-value=" ">
              </el-switch>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
      <a-button type="primary" icon="export" @click="handleExcel">导出</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
    </s-table>
    <create-form
      v-if="visible"
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import { speedy_item_list, speedy_item_create, speedy_item_excel_list } from '@/api/speedy_item'
import CreateForm from '../modules/BookForm'
import { Drawer, Tag } from 'ant-design-vue'
/*
import pick from 'lodash.pick'
*/
export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    Drawer,
    Tag
  },
  data () {
    return {
      previewvisible: false,
      loading: false,
      confirmLoading: false,
      DrawerVisible: false,
      current_speedy_item_id: null,
      // 创建窗口控制
      visible: false,
      tags: [],
      selectedTags: [],
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '一级分类',
          dataIndex: 'category',
          customRender: (text) => this.$Dictionaries.Category[text],
          ellipsis: true
        },
        {
          title: '二级分类',
          dataIndex: 'subcategory',
          customRender: (text) => this.$Dictionaries.Subcategory[text],
          ellipsis: true
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '单位',
          dataIndex: 'unit',
          ellipsis: true
        },
        {
          title: '数量',
          dataIndex: 'count',
          ellipsis: true
        },
        {
          title: '是否补录',
          dataIndex: 'is_additional',
          customRender: (text) => text ? '是' : '否'
        }/*,
        {
            title: '操作',
            dataIndex: 'action',
            width: '150px',
            scopedSlots: { customRender: 'action' }
          } */
      ],
      previewurl: '',
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return speedy_item_list(Object.assign(parameter, this.queryParam, { no_zero: this.queryParam.no_zero || '' }))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      }
    }
  },
  created () {
  },
  methods: {
    openPreview (url) {
      this.previewvisible = true
      this.previewurl = url
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleExcel () {
      speedy_item_excel_list().then((res) => {
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/octet-stream' })
        console.log(blob)
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `导出.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
   /* handleDelet (record) {
      speedy_item_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    }, */
    onCloseDrawer () {
      this.DrawerVisible = false
    },
    afterVisibleChange (val) {
      console.log('visible', val)
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.entrance.corporation_id = this.$Dictionaries.corporation_id
          values.SpeedyItem = values.entrance
          // 新增
          speedy_item_create(values.entrance)
            .then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
            .finally(() => {
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {}
    }
  }
}
</script>
