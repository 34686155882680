<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item
              v-bind="formItemLayoutWithOutLabel"
              label=""
              :required="false"
            >
              <a-select
                placeholder="请选择物品一级类别"
                @change="handlecategoryChange"
                @select="handlecategoryEntranceSelect"
                v-decorator="[
                  `entrance.category`,
                  {
                    validateTrigger: ['change', 'blur'],
                    rules: [
                      {
                        required: false,
                        message: '请选择物品类别'
                      },
                    ],
                  },
                ]">
                <a-select-option v-for="item in entrance_list" :key="item.key" :value="item.key">
                  {{ item.title }}
                </a-select-option>
              </a-select>
              <a-select
                placeholder="请选择物品二级类别"
                v-decorator="[
                  `entrance.subcategory`,
                  {
                    rules: [
                      {
                        required: false,
                        message: '请选择物品类别'
                      },
                    ],
                  },
                ]">
                <a-select-option v-for="son in father" :key="son.title" :value="son.key">
                  {{ son.title }}
                </a-select-option>
              </a-select>
              <a-input v-show="false" v-decorator="[`entrance.action`, {initialValue: 3, rules: [{required: false, message: '必填项，请填写信息'}]}]" placeholder="请填写名称"></a-input>
              <a-input v-decorator="[`entrance.name`, { rules: [{required: false, message: '必填项，请填写信息'}]}]" placeholder="请填写名称"></a-input>
              <a-input v-decorator="[`entrance.unit`, { rules: [{required: false, message: '必填项，请填写信息'}]}]" placeholder="请填写货物单位"></a-input>
              <a-input
                v-decorator="[`entrance.count`,
                              { rules: [
                                {required: false, message: '必填项，请填写信息'},
                                {pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)*$/, message: '请输入正确的数量'}
                              ]}]"
                placeholder="请填写货物数量"></a-input>
              <a-input v-decorator="[`entrance.remark`, { rules: [{required: false, message: '必填项，请填写信息'}]}]" placeholder="请填写备注"></a-input>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
// 表单字段
const fields = ['id']
let id = 0
let exit_id = 0
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      entrance: {},
      exit: {},
      entrance_list: this.$Dictionaries.GateWayItem,
      exit_list: this.$Dictionaries.GateWayItem,
      form: this.$form.createForm(this),
      father: null,
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13, offset: 7 }
        }
      }
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    this.form.getFieldDecorator('keys_entrance', { initialValue: [], preserve: true })
    this.form.getFieldDecorator('keys_exit', { initialValue: [], preserve: true })
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    handlecategoryChange (value) {
      this.father = this.$Dictionaries.GateWayItem.filter((item) => { return item.value === value })[0].children
      console.log(this.father)
    },
    handlecategoryEntranceSelect () {
      const { form } = this
      console.log(form.getFieldValue('entrance'))
      const result = form.getFieldValue('entrance')
      result.subcategory = undefined
      form.setFieldsValue({ 'entrance': result })
    },
    remove (k) {
      console.log(k, '我是key')
      const { form } = this
      // can use data-binding to get
      const keys_entrance = form.getFieldValue('keys_entrance')
      // We need at least one passenger
      if (keys_entrance.length === 1) {
        return
      }

      // can use data-binding to set
      form.setFieldsValue({
        keys_entrance: keys_entrance.filter(key => key !== k)
      })
    },

    add () {
      const { form } = this
      // can use data-binding to get
      const keys_entrance = form.getFieldValue('keys_entrance')
      const nextkeys_entrance = keys_entrance.concat(id++)
      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        keys_entrance: nextkeys_entrance
      })
    },
    remove_exit (k) {
      const { form } = this
      // can use data-binding to get
      const keys_exit = form.getFieldValue('keys_exit')
      // We need at least one passenger
      if (keys_exit.length === 1) {
        return
      }

      // can use data-binding to set
      form.setFieldsValue({
        keys_exit: keys_exit.filter(key => key !== k)
      })
    },

    add_exit () {
      const { form } = this
      // can use data-binding to get
      const keys_exit = form.getFieldValue('keys_exit')
      const nextkeys_exit = keys_exit.concat(exit_id++)
      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        keys_exit: nextkeys_exit
      })
    }
  }
}
</script>
